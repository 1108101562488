import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ReactPlayer from 'react-player/youtube';
import ImageButton from './ImageButton';
import gPlayButtonImage from '../images/buttons/button_gplay_free.png';
import appStoreButtonImage from '../images/buttons/button_app_store_free.png';
import amazonButtonImage from '../images/buttons/button_amazon.png';
import windowsButtonImage from '../images/buttons/button_windows_download.png';
import macButtonImage from '../images/buttons/button_mac_download.png';


export const ProductCard = ({
	cardStyle, youTubeId, title, text, googlePlayUrl, 
	appStoreUrl, amazonUrl, windowsUrl, macUrl, extraContent}) =>{
	
	var youTube, googlePlayButton, appStoreButton, amazonButton, windowsButton, macButton;
	
	if (youTubeId){
		youTube = (
			<div className="player-wrapper">
				<ReactPlayer 
				className="react-player" 
				url={`https://www.youtube.com/watch?v=${youTubeId}&showinfo=0`}
				width="100%" height="100%"/>
			</div>);
	}

	if (googlePlayUrl){	
		googlePlayButton = (
			<ImageButton 
				src={gPlayButtonImage} 
				onClick={() => { window.open(googlePlayUrl, "_blank") }} 
			/>);
	}

	if (appStoreUrl){
		appStoreButton =  (
			<ImageButton 
				src={appStoreButtonImage} 
				onClick={() => { window.open(appStoreUrl, "_blank") }} 
			/>);
	}

	if (amazonUrl){
		amazonButton =  (
			<ImageButton 
				src={amazonButtonImage} 
				onClick={() => { window.open(amazonUrl, "_blank") }} 
			/>);
	}

	if (windowsUrl){
		windowsButton =  (
			<ImageButton 
				src={windowsButtonImage} 
				onClick={() => { window.open(windowsUrl, "_blank") }} 
			/>);
	}
	
	if (macUrl){
		macButton =  (
			<ImageButton 
				src={macButtonImage} 
				onClick={() => { window.open(macUrl, "_blank") }} 
			/>);
	}

	return(
		<Card className="product-card" style={cardStyle}>
			{youTube}
			<Card.Body>
				{title ? <Card.Title>{title}</Card.Title>: null}
				{text? <Card.Text>{text}</Card.Text> : null}
				{googlePlayButton}
				{appStoreButton}
				{amazonButton}
				{windowsButton}
				{macButton}
				{extraContent}
			</Card.Body>
		</Card>

	);
}

ProductCard.propTypes = {
	cardStyle: PropTypes.object,
	youTubeId: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	googlePlayUrl: PropTypes.string,
	appStoreUrl: PropTypes.string,
	amazonUrl: PropTypes.string,
	windowsUrl: PropTypes.string,
	macUrl: PropTypes.string,
	extraContent: PropTypes.object
}

export const ProductCard2 = ((
    {cardStyle, img, alt, title, text, buttonImg, onClick, extraContent, video }) => 
{
	return (
	<Card className="product-card" 
		style={cardStyle}>
		{img ? <img src={img} width='auto' alt={alt}/> : null}
        {video ? <video src={video} width='auto' height='auto' controls></video> : null}
		<Card.Body>
			<Card.Title>{title}</Card.Title>
			<Card.Text>{text}</Card.Text>
			{buttonImg ? <ImageButton src={buttonImg} onClick={onClick}/>: null }
			{extraContent}
		</Card.Body>
	</Card>);
});

ProductCard2.propTypes = {
	cardStyle: PropTypes.object,
	img: PropTypes.string,
	alt: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	buttonImg: PropTypes.string,
	onClick: PropTypes.func, 
	extraContent: PropTypes.object
}