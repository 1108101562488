import React from'react';
import { ProductCard, ProductCard2 } from '../component/ProductCard';
import SmallStars from '../images/products/small_stars.gif';
import GravitoneBg from '../images/products/background_gravitone.jpg';
import WorspionageBg from '../images/products/background_ws_512.jpg';
import PaperBg from '../images/products/background_paper.jpg';
import msshBg from '../images/products/background_mssh.jpg';

import msshThumb from '../images/products/thumbnail_mssh.jpg';
import uasButton from '../images/buttons/button_uas.png';
import pm2monitThumb from '../images/products/thumbnail_pm2_monit.jpg';
import gestureFlowThumb from '../images/products/gesture_flow_banner.png';
import gitMonThumb from '../images/products/thumbnail_gitmon.png';
import githubButton from '../images/buttons/button_github.jpg';

const Products = () => (
	<div className="card-container">
		<ProductCard2
			img = {pm2monitThumb}
			alt = "PM2 Monitor Helper thumbnail"
			title = "PM2 MONITOR"
			text = {"Open source React web app for monitoring PM2 processes (as many as you want) on your server."}
			buttonImg = {githubButton}
			onClick = {() => { window.open(
				"https://github.com/Naphier/pm2_monitor",
				"_blank") }}
		/>
		<ProductCard2
			cardStyle={{backgroundImage: `url(${msshBg})`, 
				backgroundRepeat: 'no-repeat', backgroundPositionX: '50%', 
				backgroundSize: 'cover'}}
			img = {msshThumb}
			alt = "Mobile Store Screenshot Helper thumbnail"
			title = "MOBILE STORE SCREENSHOT HELPER"
			text = {"The perfect screenshot automation tool to get your Unity3D application ready "+
					"for the App Store, Play Store, and more!"}
			buttonImg = {uasButton}
			onClick = {() => { window.open(
				"https://assetstore.unity.com/packages/tools/utilities/"+
				"mobile-store-screenshot-helper-38736", 
				"_blank") }}
		/>
		<ProductCard2
			img = {gitMonThumb}
			alt = "Gitmon thumbnail"
			title = "GITMON"
			text = {"GitMon is a simple git repository monitor to let you know you've got changes to commit. Built with Electron."}
			buttonImg = {githubButton}
			onClick = {() => { window.open(
				"https://github.com/Naphier/GitMon", 
				"_blank") }}
		/>        
		<ProductCard 
			cardStyle={{backgroundImage: `url(${WorspionageBg})`, backgroundRepeat: 'no-repeat', 
						backgroundSize: 'cover', backgroundPositionX: '50%'}}
			title="WORDSPIONAGE" 
			youTubeId="7KZbIAqHsq8" 
			text="Use special operations moves against your opponents in this unique, spy-themed, 
				crossword game. Built with Unity3D. Version 2.0 coming winter 2023!"
			googlePlayUrl="http://naplandgames.com/downloads/wordspionage.apk"
		/>
        <ProductCard2
			img = {gestureFlowThumb}
			alt = "Gesture Flow thumbnail"
			title = "GESTURE FLOW"
			text = {"Free drawing reference tool for artists containing 4,000+ images (pose, face, hands, feet). Built with Flutter (Coming soon)."}
		/>
		<ProductCard 
			cardStyle={{backgroundImage: `url(${GravitoneBg})`, backgroundRepeat: 'repeat'}}
			title="GRAVITONE" 
			youTubeId="0pCrcDDkiiY" 
			text="Bounce to the beat and fling dancing orbs into their vortex before it's too 
				late in this colorful and musical game. Built with Unity3D. (No longer available)"
		/>
		<ProductCard 
			cardStyle={{backgroundImage: `url(${SmallStars})`, backgroundRepeat: 'repeat'}}
			title="SUDOKU IN SPACE" 
			youTubeId="IXV_7X9DeYE" 
			text="Solve sudoku puzzles by color or number and help Allen the Alien find his way home! Built with Unity3D."
			appStoreUrl="https://itunes.apple.com/us/app/sudoku-in-space/id902000374"
		/>
		<ProductCard 
			cardStyle={{backgroundImage: `url(${PaperBg})`, backgroundRepeat: 'no-repeat', 
						backgroundSize: 'cover'}}
			title="SHAPE TIME" 
			youTubeId="WcVdkECXQ1Y" 
			text="Catch the falling colors by swiping to the right slot! Use time slow and 
				painter moves to reach new heights. Built with App Game Kit. No longer available."
		/>
		<ProductCard 
			cardStyle={{backgroundImage: `url(${SmallStars})`, backgroundRepeat: 'repeat'}}
			title="dFENZ AE" 
			youTubeId="PPT3ID1fZkk" 
			text="Fight space junk and other threats to Earth with a variety of unique weapons. 
				Join the dFenz force today! Built with App Game Kit. No longer available."
		/>
	</div>
);

export default Products;