import './Development.css';
import { Card, Button } from 'react-bootstrap';
import UnityCert from '../images/buttons/unity_certified_developer.png';
import Sean from '../images/sean.jpg';
import React from 'react';
import ImageButton from '../component/ImageButton';
import githubButton from '../images/buttons/button_github.jpg';
import linkedinButton from '../images/buttons/button_linkedin.jpg';
import ssgiCert from '../images/buttons/ssgi-agile-leader-cert-badge.png';
import { Link } from 'react-router-dom';

const Development = ()=>{
	const imgBtnStyle = {margin: '10px 11px', height:'41px', borderRadius:'5px'};
	const imgBtnStyle2 = {margin: '10px 11px', height:'120px', borderRadius:'5px'};

    const civis = (
        <React.Fragment> 
            <a href="https://civisanalytics.com" target="_blank" rel="noreferrer">
                Civis Analytics</a>
        </React.Fragment>);

	return (
		<div className="card-container">
	<Card className="product-card" style={{width: '800px'}}>
		<Card.Body>
			<Card.Title>Software Engineering Leader Since 2012</Card.Title>
			<p><img style={{height:'100px'}} src={Sean} alt='Sean Mann'/>
			<ImageButton 
				style={imgBtnStyle2} 
				src={ssgiCert} 
				alt='Six Sigma Global Institute Agile Leader Certification'
				onClick={() => {window.open(
                    'https://certificates.sixsigmaglobalinstitute.com/93fb86a7-387f-48d7-9b87-0d5dbfbd19ea', 
                '_blank')}}/>
            </p>
			
			<Card.Text style={{textAlign:'justify'}}>
                Napland Games cofounder, Sean Mann, is a self-taught software 
                engineer leading teams in a wide variety of tech stacks since 2012. Currently, he's 
                honing his SaaS skills by leading the DevOps and SRE teams at {civis}, a 
                data science development and execution platform built on AWS. Sean is a Six Sigma 
                Global Institute certified Agile Leader and enjoys mentoring engineers and aiding 
                them in developing their careers. In his free time, he enjoys learning new software 
                frameworks and building apps for entertainment and productivity. 
			</Card.Text>
            <div style={{width:'fit-content', textAlign: 'left', margin:'auto'}}>
				<ul>
                    <li><b>Project Management</b></li>
                        <ul><li>Agile, Jira, Azure DevOps, Scrum</li></ul>
                    <li><b>DevOps</b></li>
                        <ul><li>AWS (EKS, EC2, S3, RDS MySQL/PostGRES, Redshift), Kubernetes, IaC, 
                            Pulumi, Ansible, TravisCI, Circle-CI, GitHub, Jenkins, DockerHub</li></ul>
                    <li><b>Site Reliability Engineering</b></li>
                        <ul><li>DataDog, Sumologic, CloudTrail, Sentry, SentinelOne</li></ul>
                    <li><b>Web App Development</b></li>
                        <ul><li>Ruby on Rails, Python, React, NodeJS, MongoDB</li></ul>
					<li><b>Cross-platform Development</b></li>
                        <ul><li>C#, .NET, Unity3D, UWP, Xamarin, Flutter, Electron</li></ul>
					<li><b>Mobile Development</b></li>
                        <ul><li>Java, Android, Kotlin, Flutter, iOS, Objective-C</li></ul>
				</ul>
			</div>
			<ImageButton 
				style={imgBtnStyle} 
				src={linkedinButton} 
				alt='Sean Mann on LinkedIn'
				onClick={() => {window.open('https://www.linkedin.com/in/manns41078/', '_blank')}}/>
			<ImageButton 
				style={imgBtnStyle} 
				src={githubButton} 
				alt='Sean Mann on GitHub'
				onClick={() => {window.open('https://github.com/naphier', '_blank')}}/>
            <ImageButton 
				style={imgBtnStyle} 
				src={UnityCert} 
				alt='Unity Certification (expired)'
				onClick={() => {window.open('https://github.com/naphier', '_blank')}}/>
            <br/>
			<Link to='/'><Button>Products</Button></Link>
			<Link to='/clientwork'><Button>Client Work</Button></Link>
			{/*<Link to='/otherdemos'><Button>Other Demos</Button></Link>*/}
			<Button onClick={()=>{window.open('/webdemos/')}}>Other Demos</Button>
		</Card.Body>
	</Card>
	</div>);
};

export default Development;