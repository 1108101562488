import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
	return (
	<div style={{marginTop: '50px', width:'100%'}}>
		<p style={{textAlign: 'center', marginBottom:"0"}}>
			Sorry, the page you're looking for is not available.			
		</p>
		<Link to='/'>Return Home</Link>
	</div>);
};

export default NotFound;