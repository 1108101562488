// https://formspree.io/forms

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const MyContactForm = ({chars, onCountChanged}) => {
	const [state, handleSubmit] = useForm("xpzkaobz");

	if (state.succeeded) {
		return <p>Thanks for joining!</p>;
	}
	
	return (
		<div className="card-container" style={{marginBottom: 40, display: 'flex', flexDirection: 'column'}}>
		<div style={{maxWidth: 400, margin: '0 auto 40px'}}>
			Thank you for your interest in Napland Games.
			<span style={{fontWeight: 'bold'}}>{" "}Currently we are not accepting new projects or 
			students.{" "}</span>
			If you have a question about an existing product please use the form below.
			We look forward to hearing from you!
		</div>
		<form onSubmit={handleSubmit}>
			<div>
			<label style={{width: 110, paddingRight: 10, textAlign: 'right'}} htmlFor="email">Email:</label>
			<input style={{borderRadius: 5, width: 300}}
				id="email"
				type="email"
				name="email"
			/>
			<ValidationError
				prefix="Email"
				field="email"
				errors={state.errors}
			/>
			</div>
			<div>
			<label style={{width: 110, paddingRight: 10, verticalAlign: 'top', textAlign: 'right'}} 
				htmlFor="message">Message:</label>
			<textarea
				style={{borderRadius: 5, width: 300, height: 300}}
				id="message"
				name="message"
				maxLength="500"
				onChange={(event)=>{
					onCountChanged(event.target.value.length);
				}}

			/>
			<p style={{width: 410, fontSize: '0.75rem', textAlign: 'right', margin: 'auto'}}>{chars}/500</p>
			<ValidationError
				prefix="Message"
				field="message"
				errors={state.errors}
			/>
			</div>
			<Button type="submit" disabled={state.submitting}>
				Submit
			</Button>
		</form>
		</div>
	);
}

MyContactForm.propTypes = {
	chars: PropTypes.number,
	onCountChanged: PropTypes.number
}

class ContactForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {charcount: 0};
	}

	render(){
		return <MyContactForm 
			chars={this.state.charcount} 
			onCountChanged={(val)=> {this.setState({charcount: val})}}/>
	}
}


export default ContactForm;