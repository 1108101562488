import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Nav, Navbar } from 'react-bootstrap';
import Products from './view/Products';
import Development from './view/Development';
import ContactForm from './view/ContactForm';
import ClientWork from './view/ClientWork';
import Blog from './view/Blog';
import ScrollToTop from './component/ScrollToTop';

import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import NotFound from './view/NotFound';
import {RiApps2Line, BsInfoSquare, BsPencil, FiMessageSquare} from 'react-icons/all';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand as={Link} to="/">NAPLAND GAMES</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              <RiApps2Line style={{marginTop:'-5px'}}/> Products</Nav.Link>
            <Nav.Link as={Link} to="/about">
              <BsInfoSquare style={{marginTop:'-5px'}}/> About</Nav.Link>
            <Nav.Link as={Link} to="/blog">
              <BsPencil style={{marginTop:'-5px'}}/> Blog</Nav.Link>
            <Nav.Link as={Link} to='/contact'>
              <FiMessageSquare style={{marginTop:'-5px'}}/> Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div>
        <ScrollToTop/>
        <Switch>
          <Route exact path="/" component={Products} />
          <Route exact path="/about" component={Development} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/contact" component={ContactForm} />
          <Route exact path="/clientwork" component={ClientWork} />
          <Route render={function(){
            return NotFound();
          }} />
        </Switch>
        
      </div>
      <footer style={{fontSize:'0.5rem'}}><i>Copyright 2021 Napland Games, LLC</i></footer>
      <CookieConsent
        location='bottom'
        buttonStyle={{backgroundColor: "rgb(0, 184, 255)"}}>
        This website uses cookies to enhance the user experience. <a href="/policy.html">Read our policy here.</a>
      </CookieConsent>
    </div>
    </BrowserRouter>
  );
}

export default App;
