import React from 'react';
import './ImageButton.css';

const ImageButton = (props)=>(
		<img className="image-button" 
			style={props.style} 
			src={props.src} 
			alt={props.alt} 
			onClick={props.onClick}/>
	);

export default ImageButton;