import React from'react';
import { ProductCard, ProductCard2 } from '../component/ProductCard';
import { Button } from 'react-bootstrap';

import vuzop from '../images/clients/vuzop.gif';
import vuzopOgv from '../images/clients/vuzop.ogv';
import buttonVuzop from '../images/clients/button_vuzop.png';
import eddieThumb from '../images/clients/eddie-the-yeti.jpg';
import zombiesThumb from '../images/clients/eat-this-zombies.jpg';
import shannonCom from '../images/clients/shannon-ahn.com.jpg';
import pepaThumb from '../images/clients/pepa.jpg';
import abcThumb from '../images/clients/abcesq.com.jpg';
import nameThatAppThumb from '../images/clients/name-that-app-thumb.jpg';
import shared2youThumb from '../images/clients/shared2youlogo.jpg';
import xshiftThumb from '../images/clients/x-shift_thumb.png';

import gPlayButtonImage from '../images/buttons/button_gplay_free.png';



const ClientWork = () => (
	<div className="card-container">
		<ProductCard2
			video = {vuzopOgv}
			alt = "VuzOp animation"
			title = "VuzOp"
			text = {"Logistics and Process flow data visualizations in 3D and VR giving you real-time insights and ability to explore data in a new way."}
			buttonImg = {buttonVuzop}
			onClick = {() => { window.open(
				"https://vuzop.com",
				"_blank") }}
		/>
		<ProductCard2
			img = {shannonCom}
			alt = "ShannonAhn.com thumbnail"
			title = "ShannonAhn.com"
			text = {"Sleek minimalist website for this rising author"}
			extraContent= {<Button
				onClick = {() => { window.open("https://shannonahn.com/", "_blank") }}
				>Check it out</Button>}
		/>

		{/* In Dev */}
		<ProductCard2
			img = {xshiftThumb}
			alt = "X-Shift You thumbnail"
			title = "X-Shift"
			text = {"Open world cyberpunk game. In development."}
		/>
		<ProductCard2
			img = {nameThatAppThumb}
			alt = "Name That App thumbnail"
			title = "Name That App"
			text = {"App icon trivia game. In development."}
		/>
		<ProductCard2
			img = {shared2youThumb}
			alt = "Shared 2 You thumbnail"
			title = "Shared 2 You / Powerslyde"
			text = {"App for sharing apps and an SDK for tracking user app installs for marketing analytics. Ported to Xamarin and iOS."}
		/>

		{/* Defuncts */}

		<ProductCard 
			title="Demon Archive" 
			youTubeId="fmKW_VAQQlg" 
			text="A great hidden object puzzle game published by Big Fish games."
			extraContent={
				<Button 
					onClick={()=>{
						window.open('https://www.bigfishgames.com/games/8869/demon-archive-the-adventure-of-derek/', '_blank')}}>
					BigFish</Button>}
		/>
		<ProductCard2
			img = {eddieThumb}
			alt = "Eddie The Yeti thumbnail"
			title = "Eddie The Yeti"
			text = {"Chill out with this infinite running with stylish artwork bt Ran Ernst of Ree Designs."}
			buttonImg = {gPlayButtonImage}
			onClick = {() => { window.open(
				"https://play.google.com/store/apps/details?id=com.TheToonAsylum.EddieTheYeti", 
				"_blank") }}
		/>

		<ProductCard2
			img = {zombiesThumb}
			alt = "Eat This, Zombies! thumbnail"
			title = "Eat This, Zombies!"
			text = {"Updated app from Unity 3 to Unity 5 for iOS 64-bit compatibility. Multiple bug fixes, optimization, new GUI, and more. Published for iOS and Google Play."}
			extraContent= {<Button
				onClick = {() => { window.open("https://umfer.org/", "_blank") }}
				>Check it out</Button>}
		/>	


		<ProductCard2
			img = {pepaThumb}
			alt = "Pepa Porcupine thumbnail"
			title = "Pepa Porcupine Goes to School"
			text = {"Interactive children's book for iOS and Android."}
			extraContent= {<Button>Unavailable</Button>}
		/>
		<ProductCard2
			img = {abcThumb}
			alt = "ABCEsq.com thumbnail"
			title = "ABCEsq.com"
			text = {"Managed Wordpress migration and data recovery."}
			extraContent= {<Button
				onClick = {() => { window.open("https://abcesq.com/", "_blank") }}
				>Check it out</Button>}
		/>
	</div>
);

export default ClientWork;